import React, { useEffect, useState } from 'react';
import Fade from 'react-reveal/Fade';
import Tilt from 'react-tilt';
import { Container, Row, Col, Modal, Button } from 'react-bootstrap';

const Projects = ({ data }) => {
  return (
    <section id="projects">
      <Container>
        <div className="project-wrapper">
        <h2 className="display-4 mb-5 text-center">Projects</h2>
          {data.map((project) => (
            <Project project={project}/>
            ))}
        </div>
      </Container>
    </section>
  );
};

const Project = ({ project }) => {
  const { title, info, gif, repo, img, id, tech } = project;
  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (window.innerWidth > 769) {
      setIsDesktop(true);
      setIsMobile(false);
    } else {
      setIsMobile(true);
      setIsDesktop(false);
    }
  }, []);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <Row key={id}>
        <Col lg={4} sm={12}>
          <Fade
            left={isDesktop}
            bottom={isMobile}
            duration={1000}
            delay={500}
            distance="30px"
          >
            <div className="project-wrapper__text">
              <h3 className="project-wrapper__text-title">{title}</h3>
              <div>
                <p>
                  <span class="font-weight-bold">Tech Stack:</span> {tech}
                </p>
              </div>
              <div>
                <p>
                  {info}
                </p>
              </div>
              {gif && (
                <div
                  className="cta-btn cta-btn--hero"
                  onClick={handleShow}
                >
                  See Demo
                </div>
              )}
              {repo && (
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  className="cta-btn text-color-main"
                  href={repo}
                >
                  Source Code
                </a>
              )}
            </div>
          </Fade>
        </Col>
        <Col lg={8} sm={12}>
          <Fade
            right={isDesktop}
            bottom={isMobile}
            duration={1000}
            delay={1000}
            distance="30px"
          >
            <div className="project-wrapper__image">
              <div>
                <Tilt
                  options={{
                    reverse: false,
                    max: 8,
                    perspective: 1000,
                    scale: 1,
                    speed: 300,
                    transition: true,
                    axis: null,
                    reset: true,
                    easing: 'cubic-bezier(.03,.98,.52,.99)',
                  }}
                >
                  <div data-tilt className="thumbnail rounded">
                    <img class="img-fluid" alt="" src={img} />
                  </div>
                </Tilt>
              </div>
            </div>
          </Fade>
        </Col>
      </Row>
      <Modal centered show={show} dialogClassName="project-modal" onHide={handleClose}>
        <Modal.Body>
          <img src={gif} class="img-fluid" alt=''/>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" className="cta-btn cta-btn--hero" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
export default Projects;
