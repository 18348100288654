// Navigation Bar SECTION
const navBar = {
  show: true,
};

// Main Body SECTION
const mainBody = {
  gradientColors: "#4484ce, #1ad7c0, #ff9b11, #9b59b6, #ff7f7f, #ecf0f1",
  firstName: "Jasper",
  lastName: "Chen",
  message: " Full Stack Software Engineer ",
  icons: [
    {
      image: "fa-github",
      url: "https://github.com/JasperChen97",
    },
    {
      image: "fa-linkedin",
      url: "https://www.linkedin.com/in/jasperchen97/",
    },
    {
      image: "fa-twitter",
      url: "https://twitter.com/jaaspercchen",
    }
  ]
};

// ABOUT SECTION
const about = {
  show: true,
  heading: "About Me",
  imageLink: require("../editable-stuff/jasperchen.jpeg"),
  imageSize: 375,
  message:
    "Hello, I’m Jasper, a full stack software engineer based in Northern California. While I started out my professional career in finance and marketing, I came to discover my skillsets had more room for creative development and growth in software engineering. I enjoy working with the ins and outs of new technology —from laying down the minute details in a well-designed and responsive web applications to integrating smaller parts to a larger system. As someone who fully believes in the extensive potential of technology to impact our world at large, I am eager to be at the forefront of that exploration.",
    resume: require("../editable-stuff/Jasper_Chen_Resume.pdf"),
};

//PROJECT SECTION
const projectsData = {
  show: true,
  dataset: [
    {
      id: 1,
      img: require("../editable-stuff/AdidasHome.png"),
      title: 'Adidas Web Application',
      tech:'Node.js, Express, React, MySQL, styled-components, AWS, Webpack',
      info: 'A full-stack web replica of Adidas\'s product page composed of three different microservices linked together through a proxy server. Users are able to browse through different products through the randomly generated data seeded in MySQL.',
      gif: require("../editable-stuff/AdidasGif.gif"),
      repo: 'https://github.com/YodellingPickles/Adidas-Main-Carousel-Product-Specs',
    },
    {
      id: 2,
      img: require("../editable-stuff/TripAdvisor.png"),
      title: 'TripAdvisor Back-end Optimization',
      tech:'Node.js, Express, PostgreSQL, Neo4j, NGINX, Loader.io, k6, New Relic',
      info: 'Optimized PostgreSQL database containing over 40M unique datasets through load balancing, indexing, and caching. Resulting in being able to handle over 3000 request per second with a 0% error rate and an average latency of 78ms.',
      gif: '',
      repo: 'https://github.com/JasperChen97/TripAdvisor-Q-A-1',
    },
    {
      id: 3,
      img: require("../editable-stuff/3dgame.png"),
      title: '3-D RPG Game',
      tech:'Vanilla JavaScript, HTML, CSS, Three.js',
      info: 'A large-scale 3-D game, replicating a version of Unity’s GameObject architecture. Users are able to interact with the game world and AI generated through the usage of spatial hashing for collision detection. The game includes fluid player controls and dynamic camera views.',
      gif: require("../editable-stuff/3dgame.gif"),
      repo: 'https://github.com/JasperChen97/3D-RPG-Game',
    }
  ]
}

// GET IN TOUCH SECTION
const getInTouch = {
  show: true,
  heading: "Get In Touch",
  message:
    "Whether you want to get in touch, talk about a project collaboration, or just say hi, I'd love to hear from you. Simply fill the form and send me an email or you can reach me at Jasperchen19@gmail.com."
};

export { navBar, mainBody, about, projectsData, getInTouch };
